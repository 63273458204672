:root {
  --speed: 500mx;
  
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

input {
  background-color: rgb(255, 255, 255);
  padding: 6px;
  border: 1px solid gray;
}

.button-orange {
  background-color: #F17E23;
  color: white;
  padding: 8px 16px;
  border-radius: 6px;
  transition: 300ms all;

}

.button-orange:hover {
  background-color: #EA580B;
  color: white;
  padding: 8px 16px;
  border-radius: 6px;
  transform: translateY(1.5);

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.icon {
  color: #f88826;
  font-size: 24px;
}

.category-icon {
  color: #97928D;
  font-size: 5rem;
  margin: auto !important;
}

.category-icon:hover {
  color: #f88826;
  font-size: 5rem;
  margin: auto !important;
}

.category-box {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F5F8FA;
  border-radius: 12px;
  transition: 0.5s;
  padding: 10px;

}

.category-box:hover {
  cursor: pointer;
  box-shadow: rgba(255, 30, 0, 0.322) 0px 6px 10px;

}

.category-box img {
  width: 70px;
  margin: auto;
  filter: grayscale(100%);

}

.category-box:hover img {
  width: 70px;
  margin: auto;
  color: gray;
  filter: grayscale(0%);
  transition: 0.5s;
}

.category-box h2 {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 8px;
  transition: 0.5s;
}

.category-box:hover h2 {
  color: #F17E23;

}

.footer-menu li {
  margin: 0px 0 16px 0;
}

.footer-icon {
  font-size: 2.6rem;
  color: black;
}

ul {
  list-style: none;
}

.dropdown-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  transition: ease-in-out;
  transition-duration: 500ms;
}

.dropdown-menu.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateX(60px);
  transition: ease-in-out;
  transition-duration: 500ms;

}

.dropdown-menu ul li {
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.dropdown-menu::before {
  content: '';
  position: absolute;
  left: -10px;
  height: 20px;
  width: 20px;
  background: white;
  transform: rotate(45deg);
}

@media only screen and (max-width: 1180px) {
  .category-box {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F5F8FA;
    border-radius: 12px;
    transition: 0.5s;
    padding: 2px;

  }

  .category-box img {
    width: 60px;
    margin: auto;
    filter: grayscale(100%);

  }

  .category-box h2 {
    font-size: .9rem;
    font-weight: 500;
    margin-top: 8px;
    transition: 0.5s;
  }
}

@media only screen and (max-width: 800px) {
  .category-box {
    width: 80px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F5F8FA;
    border-radius: 12px;
    transition: 0.5s;
    padding: 2px;

  }

  .category-box img {
    width: 45px;
    margin: auto;
    filter: grayscale(100%);

  }

  .category-box h2 {
    font-size: .5rem;
    font-weight: 500;
    margin-top: 8px;
    transition: 0.5s;
  }
}